<template>
    <BCol :md="props.cols" class="mb-3">
        <label v-if="props.label" :class="`form-label${props.required ? ' form-label-required' : ''}`">{{ props.label }}</label>

        <BFormRadioGroup
            v-model="value"
            :options="options"
            :state="v == null ? null : v.$errors.length == 0"
            :disabled="$attrs.disabled"
        />

        <BFormInvalidFeedback v-for="(error, index) of errors()" :key="`error${index}`">{{ error.$message }}</BFormInvalidFeedback>
    </BCol>
</template>

<script setup>
    import { defineProps, defineEmits, computed } from "vue"
    
    const emit = defineEmits(['update:modelValue', 'change'])
    const props = defineProps({
        modelValue: {  },
        options: { type: Array, required: true, default: new Array() },
        required: { type: Boolean, default: false },
        label: { type: String },
        cols: { required: true },        
        v: { type: Object, required: false, default: null }
    })
    const errors = () => {
        if (props.v == null) {
            return []
        }
        return props.v.$errors
    }
    const value = computed({
        get: () => {
            return props.modelValue
        },
        set: (v) => {
            emit('change', v)
            emit('update:modelValue', v)
        }
    })

</script>