import { atobV2 } from "./functions"

const getRoles = ()  => {
    var tk = localStorage.getItem("mention.token")
    if ((tk ?? '') != '') {
        return JSON.parse(atobV2(tk.split(".")[1])).role
    }
    return []
}

const internalIsInRole = (roles, userRoles) => {
    var found = false;
    if (roles != null) {
        if (Array.isArray(roles)) {
            for (var idx = 0; idx < roles.length; idx++) {
                if (userRoles.indexOf(roles[idx]) != -1) {
                    found = true;
                    break;
                }
            }
        } else {
            found = userRoles.indexOf(roles) != -1;
        }
    }
    return found
}

const checkAuthorize = (el, roles) => {
    let userRoles = getRoles()
    if (roles.value != null) {
        var found = internalIsInRole(roles.value, userRoles);
        if (!found) {
            el.remove();
        }
    }
}

const addAuthorizeDirective = (app) => {
    app.directive('authorize', {
        mounted(el, roles) {
            checkAuthorize(el, roles)
        },
        updated(el, roles) {
            checkAuthorize(el, roles)
        }
    })

    //alguns casos o v-authorize não serve (BTab por exemplo)
    //entao crar uma validacao se usuario tem a role
    app.config.globalProperties.$isInRoles = (roles) => {
        
        let userRoles = getRoles()
        if (userRoles != null) {
            return internalIsInRole(roles, userRoles)
        }
        return false
    }
}

export const isInRoles = (roles) => {
    let userRoles = getRoles()
    

    if (userRoles != null) {
        return internalIsInRole(roles, userRoles)
    }
    return false
}

export default addAuthorizeDirective
