import Roles from "@/utils/roles"

export default [
  {
    path: "/relatorio-resultados",
    name: "relatorio-resultados",
    component: () => import("@/views/area-cliente/relatorio-resultados/index.vue"),
    meta: {
      title: 'Relatório de Resultados',
      authRequired: true,
      roles: [Roles.LerMeusResultados]
    },
  },
  {
    path: "/meus-resultados/:id",
    name: "meus-resultados",
    component: () => import("@/views/area-cliente/relatorio-resultados/preview-relatorio.vue"),
    meta: {
      title: 'Meu Relatório de Resultados',
      authRequired: true,
      roles: [Roles.LerMeusResultados]
    },
  },
];