import * as signalR from "@microsoft/signalr"
import { ref } from "vue";
import store from "@/state/store";
import { jwtDecode } from "jwt-decode";

import { useToast } from 'vue-toastification'
import { openModalError } from "@/state/helpers";
import logout from "@/utils/logout";
import router from "@/router/index";
import { formatDateTime } from "@/utils/moment.filters";

const toast = useToast()
const toastOptions = {
    position: "bottom-right",
    timeout: false,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
    isWs: true
}

const connection = ref(null)

const maxLength = (txt, max) => {
    if (txt.length > max) {
        return txt.substring(0, max)+"..."
    }
    return txt
}

export const notificacaoTopics =
{
    MessageRead: "msgRead",
    NotificacaoAdded: "msgAdded",
    GenericTopic: "genericTopic",
    UpdateUsersCount: "usersCount",
    OrderChatAdded: "orderChat",
    PlanComChatAdded: "planComChat",
    ClienteChatAdded: "clienteChat",
    OrderChatDelete: "orderChatDelete",
    PlanComChatDelete: "planComChatDelete",
    ClienteChatDelete: "clienteChatDelete",
    Logout: "forceLogout",
    UpdateSugestoesCount: "updateSugestoesCount"
}

const ACTION = 'websocket/messageReceived';
const MUTATION = 'websocket/MESSAGE_RECEIVED';

const NOTIFICACOES_COUNT_UPDATE = 'NOTIFICACOES_COUNT_UPDATE';
const SUGESTOES_COUNT_UPDATE = 'SUGESTOES_COUNT_UPDATE';
const NOTIFICACOES_NEW_CLIENT = 'NOTIFICACOES_NEW_CLIENT';
const USERS_COUNT_UPDATE = 'USERS_COUNT_UPDATE'
const ORDER_CHAT_UPDATE = 'ORDER_CHAT_UPDATE'
const PLANCOM_CHAT_UPDATE = 'PLANCOM_CHAT_UPDATE'
const CLIENTE_CHAT_UPDATE = 'CLIENTE_CHAT_UPDATE'
const ORDER_CHAT_DELETE = 'ORDER_CHAT_DELETE'
const PLANCOM_CHAT_DELETE = 'PLANCOM_CHAT_DELETE'
const CLIENTE_CHAT_DELETE = 'CLIENTE_CHAT_DELETE'

export const actionTypes = {
    isNotificacoesCountUpdate: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === NOTIFICACOES_COUNT_UPDATE
    },
    isSugestoesCountUpdate: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === SUGESTOES_COUNT_UPDATE
    },
    isNewClient: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === NOTIFICACOES_NEW_CLIENT
    },
    isUpdateQtdUsers: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === USERS_COUNT_UPDATE
    },
    isOrderChatUpdate: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === ORDER_CHAT_UPDATE
    },
    isPlanoComunicacaoChatUpdate: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === PLANCOM_CHAT_UPDATE
    },
    isClienteChatUpdate: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === CLIENTE_CHAT_UPDATE
    },
    isClienteChatDelete: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === CLIENTE_CHAT_DELETE
    },
    isPlanoComunicacaoChatDelete: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === PLANCOM_CHAT_DELETE
    },
    isOrderChatDelete: (mutation) => {
        return mutation.type == MUTATION && 
            mutation.payload.type === ORDER_CHAT_DELETE
    }
}

var tk = () => { 
    return localStorage.getItem('mention.token'); 
}

const deveExibirNotificacoes = () => {
  return sessionStorage.getItem('mention.naoExibirNotificacoes') !== 'true';
}

export default class WsClient {

    disconnect() {
        connection.value = null
        let $this = this
        console.log('Ws desconectado')
        setTimeout(() => {
            if ($this.init) {
                $this.init()
            }
        }, 15000)
    }

    init() {
        try {
            connection.value = new signalR.HubConnectionBuilder()
                .withUrl(process.env.VUE_APP_WSURL, { 
                    accessTokenFactory: tk,
                    transport: signalR.HttpTransportType.WebSockets  
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();
            
            this.start()
        } catch (err) {
            console.log(err);
            setTimeout(() => this.init(), 5000);
        }
    }

    async sendHeartbeat() {
        try {
            await connection.value.invoke("SendHeartbeat");
        } catch (err) {
            console.log(err);
            let $this = this
            setTimeout(() => {
                if ($this.init) {
                    $this.init()
                }
            }, 15000)
        }
    }

    async start() {
        if (localStorage.getItem('mention.token') == null) {
            console.log('Ws não iniciado. Nao autenticado', new Date())
            setTimeout(() => this.start(), 30000);
            return
        }

        console.log('start ws', connection.value.state)
        //console.log('start ws', connection.value.state == "Disconnected")
        if (connection.value.start && connection.value.state == "Disconnected") {

            connection.value.start().then(() => 
            {
                console.log('Conexão iniciada')
                setInterval(this.sendHeartbeat, 30000);
                connection.value.onclose(async () => {
                    setTimeout(this.start, 5000);
                });
                //message read
                connection.value.on(notificacaoTopics.MessageRead, (message) => {
                    store.dispatch(ACTION, {
                        type: NOTIFICACOES_COUNT_UPDATE,
                        data: JSON.parse(message)
                    })
                });
                //message added
                connection.value.on(notificacaoTopics.NotificacaoAdded, (message) => {
                    store.dispatch(ACTION, {
                        type: NOTIFICACOES_COUNT_UPDATE,
                        data: JSON.parse(message)
                    })
                });
                connection.value.on(notificacaoTopics.UpdateSugestoesCount, (message) => {
                    store.dispatch(ACTION, {
                        type: SUGESTOES_COUNT_UPDATE,
                        data: JSON.parse(message)
                    })
                });
                //update users count
                connection.value.on(notificacaoTopics.UpdateUsersCount, (message) => {
                    store.dispatch(ACTION, {
                        type: USERS_COUNT_UPDATE,
                        data: JSON.parse(message)
                    })
                });
                //order chat update
                connection.value.on(notificacaoTopics.OrderChatAdded, (message) => {
                    const data = JSON.parse(message)
                    if (sessionStorage.getItem(data.identificador) == null) {
                        sessionStorage.setItem(data.identificador, 'true')
                        const id = data.pedidoId
                        const userId = localStorage.getItem('mention.userid')

                        if (
                            data.displayToast && 
                            userId != data.usuarioId && 
                            deveExibirNotificacoes() &&
                            (
                                (
                                    (router.currentRoute.value.name == 'pedidos-view' || router.currentRoute.value.name == 'meus-pedidos-ver') 
                                        && id != router.currentRoute.value.params.id
                                ) || 
                                (router.currentRoute.value.name != 'pedidos-view' && router.currentRoute.value.name != 'meus-pedidos-ver')
                            )
                        ) {
                            let toastId;
                            const jsx = (
                                <span class="fs-sm cursor-pointer" onClick={() => {
                                    this.irParaPedido(data.pedidoId, toastId);
                                  }}>
                                    <div>
                                        {`Novo chat em #${data.pedidoId} - ${maxLength(data.comentario, 250)}`}
                                    </div>
                                    <div style={{ textAlign: 'right', fontSize: '0.7rem'}}>
                                        {`às ${formatDateTime(data.dtCriacao)} por ${data.usuario}`}
                                    </div>
                                </span>
                            );
                            toastId = toast.info(jsx, toastOptions);
                        } 
                    }

                    store.dispatch(ACTION, {
                        type: ORDER_CHAT_UPDATE,
                        data: data
                    })
                });

                connection.value.on(notificacaoTopics.OrderChatDelete, (message) => {
                    const data = JSON.parse(message)

                    store.dispatch(ACTION, {
                        type: ORDER_CHAT_DELETE,
                        data: data
                    })
                });

                //plan com chat update
                connection.value.on(notificacaoTopics.PlanComChatAdded, (message) => {
                    const data = JSON.parse(message)

                    if (sessionStorage.getItem(data.identificador) == null) {
                        sessionStorage.setItem(data.identificador, 'true')

                        const id = data.planoComunicacaoId
                        const userId = localStorage.getItem('mention.userid')

                        if (
                            data.displayToast && 
                            userId != data.usuarioId &&
                            deveExibirNotificacoes() &&
                            router.currentRoute.value.name != 'meu-plano-comunicacao' &&
                            (
                                ((router.currentRoute.value.name == 'clientes-editar') && id != router.currentRoute.value.params.id) || 
                                router.currentRoute.value.name != 'clientes-editar'
                            )
                        ) {
                            const jsx = (
                                <span class="fs-sm" v-html={`Novo chat no plano de comunicação${(localStorage.getItem('mention.tipousuario') == 'mention' ? ` do cliente ${data.cliente}` : '')} - ${maxLength(data.comentario, 250)}`}></span>
                            );
                            toast.info(jsx, toastOptions);
                        } 
                    }

                    store.dispatch(ACTION, {
                        type: PLANCOM_CHAT_UPDATE,
                        data: data
                    })
                });

                connection.value.on(notificacaoTopics.PlanComChatDelete, (message) => {
                    const data = JSON.parse(message)

                    store.dispatch(ACTION, {
                        type: PLANCOM_CHAT_DELETE,
                        data: data
                    })
                });

                connection.value.on(notificacaoTopics.ClienteChatAdded, (message) => {
                    const data = JSON.parse(message)

                    if (sessionStorage.getItem(data.identificador) == null) {
                        sessionStorage.setItem(data.identificador, 'true')
                        const userId = localStorage.getItem('mention.userid')
                        if (
                            userId != data.usuarioId &&
                            deveExibirNotificacoes() &&
                            data.displayToast
                        ) {
                            const jsx = (
                                <span class="fs-sm" v-html={`Novo chat geral ${(localStorage.getItem('mention.tipousuario') == 'mention' ? ` do cliente ${data.cliente}` : '')} - ${maxLength(data.comentario, 250)}`}></span>
                            );
                            toast.info(jsx, toastOptions);
                        } 
                    }

                    store.dispatch(ACTION, {
                        type: CLIENTE_CHAT_UPDATE,
                        data: data
                    })
                });

                connection.value.on(notificacaoTopics.ClienteChatDelete, (message) => {
                    const data = JSON.parse(message)
                    store.dispatch(ACTION, {
                        type: CLIENTE_CHAT_DELETE,
                        data: data
                    })
                });

                //logoff
                connection.value.on(notificacaoTopics.Logout, (message) => {
                    const data = JSON.parse(message)
                    if (localStorage.getItem('mention.token')) {
                        const decodedToken = jwtDecode(localStorage.getItem('mention.token'));
                        if (!data.tokenIgnore || data.tokenIgnore != decodedToken.jti) {
                            logout(null)
                            if (data.origem == 'CHANGEPASSWORD') {
                                openModalError('PASSWORD_CHANGED')
                            }
                            if (data.origem == 'CHANGEUSER') {
                                openModalError('USER_CHANGED')
                            }
                        }
                    }
                });

                //genric topic
                connection.value.on(notificacaoTopics.GenericTopic, (message) => {
                    const data = JSON.parse(message)
                    if (sessionStorage.getItem(data.identificador) == null) {
                        sessionStorage.setItem(data.identificador, 'true')
                        
                        if (deveExibirNotificacoes()) {    
                            const jsx = (
                                <span class="fs-sm" v-html={data.text}></span>
                            );
                            toast.info(jsx, toastOptions);
                        }
                    }
                    store.dispatch(ACTION, {
                        type: data.topic,
                        data: data
                    })
                });
            })
            .catch(err => console.error('Erro ao conectar', err))
        }
    }

    irParaPedido = (pedidoId, toastId) => {
        const routeName = store.getters['account/isCliente'] ? 'meus-pedidos-ver' : 'pedidos-view'
        router.push({ name: routeName, params: { id: pedidoId } });
        toast.dismiss(toastId)
    };
}

const wsCliente = ref(new WsClient());

function useWebsocket() {

    const disconnect = () => {
        wsCliente.value.disconnect()
    }

    const tryStart = () => {
        wsCliente.value.init();
    }

    return {
        tryStart,
        disconnect
    }
}

export {
    useWebsocket
}