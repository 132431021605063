<template>
    <BCol :md="props.cols" class="mb-3">
      <label v-if="props.label" :class="`form-label${props.required ? ' form-label-required' : ''}`">{{ props.label }}</label>
      <p v-if="props.title" class="form-text text-muted mb-1">{{ props.title }}</p>
      <div class="search-box">
        <BFormInput 
            :type="props.type" :state="v == null ? null : v.$errors.length == 0" trim 
            v-model="value" :disabled="$attrs.disabled" :readonly="$attrs.readonly"
            :placeholder="$attrs.placeholder" @keyup.enter="emit('keyEnter')"
            class="search"
        ></BFormInput>
        <i class="ri-search-line search-icon"></i>
      </div>
      <BFormInvalidFeedback v-for="(error, index) of errors()" :key="`error${index}`">{{ error.$message }}</BFormInvalidFeedback>
    </BCol>
</template>

<script setup>
    import { defineProps, defineEmits, computed } from "vue"
    
    const emit = defineEmits(['update:modelValue', 'keyEnter'])
    const props = defineProps({
        modelValue: {},
        required: { type: Boolean, default: false },
        label: { type: String },
        cols: { required: true },
        type: { type: String, required: false, default: 'text' },
        v: { type: Object, required: false, default: null },
        title: { type: String, required: false, default: null }
    })
    const errors = () => {
        if (props.v == null) {
            return []
        }
        return props.v.$errors
    }
    const value = computed({
        get: () => {
            return props.modelValue
        },
        set: (v) => {
            emit('update:modelValue', v)
        }
    })

</script>

<style scoped>
  .form-text.text-muted {
    margin: 0;
  }
</style>