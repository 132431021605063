<template>
    <BCol :md="props.cols" class="mb-3">
        <label v-if="props.label" :class="`form-label${props.required ? ' form-label-required' : ''}`">{{ props.label }}</label>
        <vSelect v-model="value" 
            :options="options" 
            label="text"
            :state="v == null ? null : v.$errors.length == 0"
            :placeholder="props.placeholder"
            :disabled="$attrs.disabled"
            :multiple="props.multiple"
            :class="`${v == null ? '' : (v.$errors.length > 0 ? 'is-invalid' : '')}`"
        >
            <template v-slot:no-options="{ search, searching }">
                <template v-if="searching"> 
                    Sem resultados para <em>{{ search }}</em>.
                </template>
                <em v-else style="opacity: 0.5">Começe a digitar para pesquisar.</em>
            </template>
        </vSelect>
        <BFormInvalidFeedback v-for="(error, index) of errors()" :key="`error_${index}_${error.$message}`">{{ error.$message }}</BFormInvalidFeedback>
    </BCol>
</template>

<style>
@import "vue-select/dist/vue-select.css";
</style>

<script setup>
    import vSelect from "vue-select";

    import { defineProps, defineEmits, computed } from 'vue';

    const emit = defineEmits(['update:modelValue', 'change'])
    const props = defineProps({
        modelValue: {  },
        addEmpty: { type: Boolean, default: true },
        required: { type: Boolean, default: false },
        label: { type: String },
        placeholder: { type: String, default: 'Digite para iniciar pesquisa' },
        cols: { required: true },        
        v: { type: Object, required: false, default: null },
        multiple:  { type: Boolean, default: false },
        options: { type: Array, default: new Array() }
    })


    const options = computed(() => {
        if (props.addEmpty) {
            return [{text: 'Selecione', value: ''}, ...props.options]
        }
        return props.options
    })


    const errors = () => {
        if (props.v == null) {
            return []
        }
        return props.v.$errors
    }

    const value = computed({
        get: () => {
            if (Array.isArray(props.modelValue)) {
                let re = props.options.filter(m => props.modelValue.includes(m.value))
                return re
            } else {
                const filtered = props.options.filter(m => m.value == props.modelValue)
                return  filtered.length > 0 ? filtered[0] : null
            }
        },
        set: (v) => {
            if (Array.isArray(v)) {
                emit('update:modelValue', v.map(m => m.value))
                emit('change', v.map(m => m.value))
            } else {
                emit('update:modelValue', v ? v.value : null)
                emit('change', v ? v.value : null)
            }
        }
    })

</script>