<template>
    <BCol :md="props.cols" class="mb-3">
        <label v-if="props.label"  :class="`form-label${props.required ? ' form-label-required' : ''}`">{{ props.label }}</label>
        <div class="input-group" :state="v == null ? null : v.$errors.length == 0">
            <FlatPickr 
                v-model="value"
                :class="`form-control${(v == null || v.$errors.length == 0 ? '' : ' border-danger')}`" 
                :state="v == null ? null : v.$errors.length == 0"
                :disabled="props.disabled" :readonly="props.readonly"
                :config="{ 
                    mode: props.mode, 
                    altInput: true, 
                    altFormat: props.altFormat, 
                    dateFormat: props.dateFormat, 
                    enableTime: props.enableTime,
                    wrap: true,
                    locale: ptLocale,
                    onChange: (selectedDates, dateStr, instance) => emit('change', selectedDates, dateStr, instance)
                }">
            </FlatPickr>
            <button v-show="showClear()" 
                    class="input-group-text bg-primary text-white" 
                    title="clear" data-clear >
                <i class="fas fa-times"></i>
            </button>
        </div>
        <BFormInvalidFeedback v-for="(error, index) of errors()" :key="`error${index}`">{{ error.$message }}</BFormInvalidFeedback>
    </BCol>
</template>

<script setup>
    import { defineProps, defineEmits, computed } from "vue"
    import FlatPickr from "vue-flatpickr-component";
    import {Portuguese as ptLocale} from 'flatpickr/dist/l10n/pt';
    
    const emit = defineEmits(['update:modelValue', 'change'])
    const props = defineProps({
        modelValue: {  },
        mode: { type: String, default: 'single'},

        altFormat: { type: String, default: 'd/m/Y'},
        dateFormat: { type: String, default: 'Y-m-d'},

        required: { type: Boolean, default: false },

        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },

        label: { type: String },
        enableTime: { type: Boolean, default: false },
        cols: { required: true },        
        v: { type: Object, required: false, default: null }
    })

    const errors = () => {
        if (props.v == null) {
            return []
        }
        return props.v.$errors
    }

    const showClear = () => {
        if (props.disabled || props.readonly) {
            return false
        }
        if (Array.isArray(value.value)) {
            return value.value.filter(m => m).length > 0
        }
        return value.value && value.value.length > 0
    }

    const value = computed({
        get: () => {
            return props.modelValue
        },
        set: (v) => {
            emit('update:modelValue', v)
        }
    })

</script>